import { isActiveTab } from "./eduson_helpers.js"

let UserActivity = {
    init(socket, element) {
        if (!element || !import.meta.env.VITE_TRACKER_SOCKET || import.meta.env.VITE_TRACKER_SOCKET.length < 0) { return }

        let lectureId = element.getAttribute("data-id")

        socket.connect()
        this.onReady(lectureId, socket)
    },

    onReady(lectureId, socket) {
        let lectureChannel = socket.channel("user_activity:" + lectureId)

        lectureChannel.join()
            .receive("ok", resp => console.log("joined the lecture channel", resp))
            .receive("error", reason => console.log("join failed", reason) )

        isActiveTab(() => {
            if (isActiveTab()) {
                lectureChannel.push("active", {})
                              .receive("error", e => console.log(e))
            } else {
                lectureChannel.push("not_active", {})
                              .receive("error", e => console.log(e))
            }
        })
    }
}
export default UserActivity
