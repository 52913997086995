function activeTab() {
    let stateKey, eventKey, keys = {
        hidden: "visibilitychange",
        webkitHidden: "webkitvisibilitychange",
        mozHidden: "mozvisibilitychange",
        msHidden: "msvisibilitychange"
    }

    for (stateKey in keys) {
        if (stateKey in document) {
            eventKey = keys[stateKey];
            break;
        }
    }
    return (callback) => {
        if (callback) document.addEventListener(eventKey, callback);
        return !document[stateKey];
    }
}

export function isActiveTab(callback) {
    return activeTab()(callback)
}
